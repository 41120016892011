import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { Form, Alert, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import { Button, Container, Divider, Grid, makeStyles, MenuItem, TextField, ThemeProvider, List } from '@material-ui/core';
import styled from "styled-components";
import { mainTheme, greenTheme } from "../styles/themes";
import { Firm, HeadnoteFirmSettings, ClientPortalSettings } from 'domain/firm';
import { type, Link2PayTemplate, HEADNOTE_BANK_ACCOUNT_TYPES } from 'domain/payment';
import Feedback from 'react-bootstrap/Feedback';
import { Colors, } from 'assets/index';
import Link2PayCreditCardV from './components/Link2PayCreditCardV';
import Link2PayECheckARNumbersV from './components/Link2PayECheckARNumbersV';
import Link2PayECheckPlaidV from './components/Link2PayECheckPlaidV';
import { LINK2PAY_PAYMENT_METHODS } from 'domain/payment';
import { currencyFormat, formatNumber } from 'utilities/stringUtils';
import * as yup from 'yup';
import { otherwise } from 'ramda';
import MaskedInput from "react-text-mask";
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

//const trackMixpanelEventAddRecipient = () => { window.mixpanel.track("b4tp_link_2_pay_add_a_recipient"); }
//const trackMixpanelEventTOS = () => { window.mixpanel.track("b4tp_client_portal_terms_and_conditions"); }

const StyledFormError = styled(Feedback)`
    color: ${Colors.redDanger};
    display: block;
`;

const FormField = styled.span`
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  iframe {
    border: 0 none transparent;
      height: 100%;
      vertical-align: middle;
      width: 100%;
  }
`;

type Props = {
    vgsForm: object,
    vgsErrors: object,
    firm: Firm,
    link2Pay: Link2PayTemplate,
    errors: { [string]: string },
    headnoteFirmSettings: HeadnoteFirmSettings,
    paymentReceivingBankType: HEADNOTE_BANK_ACCOUNT_TYPES
}

const useStyles = makeStyles({
    form: {
        backgroundColor: '#fff',
        marginTop: '10px',
        padding: '10px',
        border: '1px solid #ced4da',
        borderRadius: '.25rem',
        boxShadow: 'rgb(204 204 204) 1px 1px 1px'
    },
    header: {
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 9
    },
    firmName: {
        fontSize: 12,
        fontWeight: "bold"
    },
    firmContactInfo: {
        fontSize: 11,
        marginBottom: 24
    },
    thankYouMessage: {
        fontSize: 12,
        overflowWrap: "break-word"
    },
    payingOnBehalfMessage: {
        fontSize: 10
    },
    subtitle: {
        fontSize: 14,
        fontWeight: "bold"
    },
    termsAndConditions: {
        textAlign: "right"
    },
    amount: {
        fontSize: 12
    },
    helperText: {
        minHeight: 0
    }
});

const StyledContainer = styled(Container)`
    Margin: 20px;
`;

const TOS = () => <a href="https://www.bill4time.com/terms-of-service" target="_blank"><strong>{`Terms & Conditions`}</strong></a>

type Link2PayPaymentMethodOption = {
    label: String;
    type: String;
    id?: String;
}

const paymentMethodOptions: Link2PayPaymentMethodOption[] = [
    {
        label: 'Credit Card',
        type: LINK2PAY_PAYMENT_METHODS.CARD,
    },
    {
        label: 'eCheck Payment via Account & Routing Numbers',
        type: LINK2PAY_PAYMENT_METHODS.ARNUMBER,
    },
    {
        label: 'eCheck Payment via Secure Bank Login',
        type: LINK2PAY_PAYMENT_METHODS.PLAID
    }
];

const defaultMaskOptions = {
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
    requireDecimal: true,
}

const createCurrencyMask = (opts = {}) => {
    const decimalsRegex = /\.([0-9]{1,2})/;
    const numberMask = createNumberMask({
        prefix: '$',
        includeThousandsSeparator: true,
        allowDecimal: true,
        requireDecimal: true,
        allowLeadingZeroes: false,
        ...opts,
    });

    return rawValue => {
        const mask = numberMask(rawValue);
        const result = decimalsRegex.exec(rawValue);

        // In case there is only one decimal
        if (result && result[1].length < 2) {
            mask.push('0');
        } else if (!result) {
            mask.push('0');
            mask.push('0');
        }

        return mask;
    };
};

const currencyPipe = (value) => {
    if (!value) {
        return value;
    }

    if (value.includes('._')) {
        return value.replace('._', '.0');
    }

    return value;
}

const currencyMask = createCurrencyMask({
    ...defaultMaskOptions
});

const CurrencyMaskCustom = (props) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={currencyMask}
            pipe={currencyPipe}
        ></MaskedInput>
    );
}

const Link2PayV = ({ link2Pay, error, submitPayment, selectedPaymentMethod, setSelectedPaymentMethod, setVgsLoadState, headnoteFirmSettings, firmInvoiceInfo, paymentReceivingBankType, ...props }: Props) => {
    const classes = useStyles();

    const [recipientList, setRecipientList] = useState([""]);
    const [isPlaidConnectionFailed, setIsPlaidConnectionFailed] = useState(false);
    const MAX_RECIPIENT_SIZE = 5;
    const showAddButton = recipientList.length < MAX_RECIPIENT_SIZE;

    const hasUnallowedPaymentMethods = !headnoteFirmSettings?.credit_card_processing_allowed || !headnoteFirmSettings?.debit_card_processing_allowed || !headnoteFirmSettings.ach_processing_allowed;
    const hasNoAllowedPaymentMethods = !headnoteFirmSettings?.credit_card_processing_allowed && !headnoteFirmSettings?.debit_card_processing_allowed && !headnoteFirmSettings.ach_processing_allowed;

    const hasCardTransactionLimit = !!headnoteFirmSettings?.card_transaction_limit_in_cents;
    const showCardLimitMessage = hasCardTransactionLimit && (headnoteFirmSettings.credit_card_processing_allowed || headnoteFirmSettings.debit_card_processing_allowed);

    const hasAchTransactionLimit = !!headnoteFirmSettings?.ach_transaction_limit_in_cents;
    const showAchLimitMessage = hasAchTransactionLimit && headnoteFirmSettings.ach_processing_allowed;

    const portalSettings: ClientPortalSettings = R.path([ 'portalSettings', ], props.firm);

    const onPaymentMethodChange = (event) => {
        setSelectedPaymentMethod(event.target.value);
        formik.setFieldValue("paymentMethod", event.target.value);

        if (event.target.value !== LINK2PAY_PAYMENT_METHODS.CARD && props.vgsForm) {
            props.vgsForm.unmount();
            props.setVgsForm(null);
            setVgsLoadState(false);
        }
    }

    const PaymentMethod = ({ error = {}, }) => {
        switch (selectedPaymentMethod) {
            case LINK2PAY_PAYMENT_METHODS.CARD:
                return <Link2PayCreditCardV
                    vgsForm={props.vgsForm}
                    error={error}
                    vgsErrors={props.vgsErrors}
                    subtitle={classes.subtitle}
                />
            case LINK2PAY_PAYMENT_METHODS.ARNUMBER:
                return <Link2PayECheckARNumbersV
                    error={error}
                    formik={formik}
                    subtitle={classes.subtitle}
                />
            case LINK2PAY_PAYMENT_METHODS.PLAID:
                return <Link2PayECheckPlaidV
                    isPlaidConnectionFailed={isPlaidConnectionFailed}
                    subtitle={classes.subtitle}
                    error={error}
                />
            default:
        }
    };

    const RecipientList = () => {
        const fieldList = recipientList.map((email, index) => {
            const isFirstInputField = index === 0;
            let helperText = " "
            let errors = false;
            if (formik.touched.recipientList && formik.errors.recipientList) {
                helperText = formik.touched.recipientList[index] && formik.errors.recipientList[index];
                errors = formik.touched.recipientList[index] && Boolean(formik.errors.recipientList[index])
            }

            const label = isFirstInputField ? "Additional Receipt Recipients" : ""

            return (
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={label}
                        placeholder={"Enter Email Address"}
                        name={"reciept_to"}
                        type={'text'}
                        variant="outlined"
                        onChange={e => updateEmail(e, index)}
                        error={errors}
                        helperText={helperText}
                    ></TextField>
                </Grid>
            )
        })

        return fieldList;
    };

    const updateEmail = (e, index) => {
        const newEmail = e.target.value;

        const newList = [...recipientList];
        newList[index] = newEmail.trim();
        setRecipientList(newList);
        formik.setFieldValue("recipientList", newList);
    }

    const addRecipient = () => {
        //trackMixpanelEventAddRecipient();
        const newList = recipientList.concat('');
        setRecipientList(newList);
    }

    const vgsFieldsCss = {
        'font-size': '14px',
        'color': '#505d7d !important'
    };

    const exactRoutingNumDigits = 9;
    const maxAccountNumDigits = 17;
    const minAccountNumDigits = 3;

    const REGEX = {
        ALPHANUMERIC: /^[a-zA-Z0-9 ]*$/,
        NUMBER: /^[0-9]*$/,
        CURRENCY: /^\$?(([1-9]\d{0,2}(,\d{3})*)|0)?\.\d{1,2}$/
    }

    let validationSchema = yup.object().shape({
        first_name: yup
            .string("Enter your First Name")
            .min(2, "First Name should be a minimum of 2 characters")
            .required("First Name is required"),
        last_name: yup
            .string("Enter your Last Name")
            .min(2, "Last Name should be a minimum of 2 characters")
            .required("Last Name is required"),
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
        isAmountFieldEnabled: yup.boolean(),
        amount: yup
            .string()
            .when("isAmountFieldEnabled", {
                is: true,
                then: yup
                    .string()
                    .matches(REGEX.CURRENCY, "Amount must be valid in USD") //This should never fail, but in here just in case
                    .test("MinMaxConstraint",
                        "Amount must be between $1.00 and $50,000.00",
                        (value) => {
                            if (value) {
                                const numberValue = Number(value.replace(/[^0-9.-]+/g, ""));
                                if (numberValue) {
                                    if (numberValue > 1 && numberValue < 50000) {
                                        return true;
                                    }
                                }
                            }
                            return false;
                        })
                    .required("Amount is Required"),
                otherwise: yup.string().notRequired()
            }),
        paymentMethod: yup.string(),
        recipientList: yup.array().of(yup
            .string()
            .email('Enter a valid email'),
        ),
        routingNumber: yup
            .string()
            .when("paymentMethod", {
                is: (value) => value === LINK2PAY_PAYMENT_METHODS.ARNUMBER,
                then: yup
                    .string()
                    .matches(REGEX.NUMBER, 'Must be a number')
                    .length(exactRoutingNumDigits, `Must be ${exactRoutingNumDigits} digits`)
                    .required("Routing Number is Required"),
                otherwise: yup.string().notRequired()
            }),
        accountNumber: yup
            .string()
            .when("paymentMethod", {
                is: (value) => value === LINK2PAY_PAYMENT_METHODS.ARNUMBER,
                then: yup
                    .string()
                    .matches(REGEX.NUMBER, 'Must be a number')
                    .min(minAccountNumDigits, `Must be greater than ${minAccountNumDigits} digits`)
                    .max(maxAccountNumDigits, `Must be less than ${maxAccountNumDigits} digits`)
                    .required("Account Number is Required"),
                otherwise: yup.string().notRequired()
            }),
        accountNumberConfirm: yup
            .string()
            .when("paymentMethod", {
                is: (value) => value === LINK2PAY_PAYMENT_METHODS.ARNUMBER,
                then: yup
                    .string()
                    .oneOf([yup.ref('accountNumber'), null], 'Account numbers must match'),
                otherwise: yup.string().notRequired()
            })
    })

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            client_id: '',
            project_id: '',
            isAmountFieldEnabled: link2Pay.amount > 0 ? false : true,
            amount: '',
            paymentMethod: LINK2PAY_PAYMENT_METHODS.CARD,
            recipientList: [],
            payment_notes: '',
            savePaymentMethod: true,
            saveForFirmUse: false
        },
        validationSchema: validationSchema,
        onSubmit: submitPayment,
        enableReinitialize: true
    });

    const UnallowedPaymentMethodsMap = {
        credit_card: 'Credit Card',
        debit_card: 'Debit Card',
        ach: 'ACH'
    }

    const unallowedPaymentMethodsStringArray: string[] = Object.entries(UnallowedPaymentMethodsMap)
        .filter(([key, _]) => !headnoteFirmSettings[`${key}_processing_allowed`])
        .map(([_, value]) => value);
    const singleUnallowedPmtMethod = unallowedPaymentMethodsStringArray.length === 1;

    const UnallowedPaymentMethodsString = () => {
        if (!headnoteFirmSettings) { return ''; }
        return unallowedPaymentMethodsStringArray.join('/');
    }

    const showSurchargeSection: boolean =
        headnoteFirmSettings.client_fee_enabled &&
        link2Pay.isSurchargeEnabled &&
        paymentReceivingBankType === HEADNOTE_BANK_ACCOUNT_TYPES.OPERATING &&
        selectedPaymentMethod === LINK2PAY_PAYMENT_METHODS.CARD;

    return (
        <StyledContainer>
            <ThemeProvider theme={mainTheme}>
                <Form onSubmit={formik.handleSubmit}>
                    <Grid className={classes.form} container spacing={4} >
                        <Grid item md={6} xs={12}>
                            <div className={`${classes.header}`}>Online Payment</div>
                            <div className={classes.firmContactInfo}>
                                <div className={classes.firmName}>{firmInvoiceInfo?.firmName}</div>
                                <div>{firmInvoiceInfo?.billAddr1}</div>
                                <div>{firmInvoiceInfo?.billCity}, {firmInvoiceInfo?.billState} {firmInvoiceInfo?.billZip}</div>
                                <div>{firmInvoiceInfo?.billCountry}</div>
                                <div>Office: {firmInvoiceInfo?.billPhone}</div>
                                <div>{firmInvoiceInfo?.billEmail}</div>
                            </div>
                            <div className={`${classes.thankYouMessage} mb-3`}>{link2Pay.message}</div>
                            <div className={`${classes.payingOnBehalfMessage} mb-4`}>If you’re paying on behalf of somebody else, please enter their contact information to make sure funds are correctly applied.</div>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className={classes.subtitle}>Payor Contact Information</div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label={"First Name"}
                                        name="first_name"
                                        type={'text'}
                                        variant="outlined"
                                        value={formik.values.first_name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                        helperText={(formik.touched.first_name && formik.errors.first_name) || " "}
                                    ></TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label={"Last Name"}
                                        name={"last_name"}
                                        type={'text'}
                                        variant="outlined"
                                        value={formik.values.last_name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                        helperText={(formik.touched.last_name && formik.errors.last_name) || " "}
                                    ></TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label={"Email Address"}
                                        name={"email"}
                                        type={'text'}
                                        variant="outlined"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={(formik.touched.email && formik.errors.email) || " "}
                                    ></TextField>
                                </Grid>
                                {link2Pay.isClientRequested ?
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            label={"Client ID"}
                                            name={"client_id"}
                                            type={'text'}
                                            variant="outlined"
                                            value={formik.values.client_id}
                                            onChange={formik.handleChange}
                                            error={formik.touched.client_id && Boolean(formik.errors.client_id)}
                                            helperText={(formik.touched.client_id && formik.errors.client_id) || " "}
                                        ></TextField>
                                    </Grid> : ""
                                }
                                {link2Pay.isProjectRequested ?
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            label={`${props.firm.projectLabel} ID`}
                                            name={"project_id"}
                                            type={'text'}
                                            variant="outlined"
                                            value={formik.values.project_id}
                                            onChange={formik.handleChange}
                                            error={formik.touched.project_id && Boolean(formik.errors.project_id)}
                                            helperText={(formik.touched.project_id && formik.errors.project_id) || " "}
                                        ></TextField>
                                    </Grid> : ""
                                }
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className={classes.subtitle}>Payment Summary</div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {
                                        (link2Pay.amount > 0) ?
                                            <div className={classes.amount}>{currencyFormat(link2Pay.amount, props.firm)}</div>
                                            :
                                            <TextField
                                                required
                                                fullWidth
                                                label={"Payment Amount (USD)"}
                                                name={"amount"}
                                                type={'text'}
                                                variant="outlined"
                                                value={formik.values.amount}
                                                InputProps={{
                                                    inputComponent: CurrencyMaskCustom
                                                }}
                                                onChange={formik.handleChange}
                                                error={formik.touched.amount && Boolean(formik.errors.amount)}
                                                helperText={(formik.touched.amount && formik.errors.amount) || " "}
                                            ></TextField>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        onChange={onPaymentMethodChange}
                                        label={"Payment Method"}
                                        placeholder={"Debit / Credit Card"}
                                        name={"payment_method"}
                                        type={'text'}
                                        variant="outlined"
                                        value={selectedPaymentMethod}
                                        select
                                    >
                                        {paymentMethodOptions.map((option) => (
                                            <MenuItem key={option.type} value={option.type}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {hasUnallowedPaymentMethods &&
                                    <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px', textAlignLast: 'center' }}>
                                        <div style={{ display: 'inline-block' }}>
                                            <span style={{ display: 'inline-flex', fontWeight: 'bold', fontStyle: 'italic' }}>{UnallowedPaymentMethodsString()}</span> {singleUnallowedPmtMethod ? 'is' : 'are'} not allowed for this payment. Any payment method of {singleUnallowedPmtMethod ? 'this type' : 'these types'} will be rejected.
                                        </div>
                                    </Grid>
                                }
                                {((hasCardTransactionLimit || hasAchTransactionLimit) && !hasNoAllowedPaymentMethods) &&
                                    <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px', textAlignLast: 'center' }}>
                                        <div style={{ display: 'inline-block' }}>
                                            <span style={{ display: 'inline-flex' }}>
                                                {showCardLimitMessage ? `Maximum Credit/Debit Card payment is ${currencyFormat(Math.ceil(headnoteFirmSettings.card_transaction_limit_in_cents / 100), props.firm)}.` : ''}
                                                {showAchLimitMessage ? ` Maximum ACH payment is ${currencyFormat(Math.ceil(headnoteFirmSettings.ach_transaction_limit_in_cents / 100), props.firm)}.` : ''}
                                            </span>
                                            <span>
                                                {showCardLimitMessage && showAchLimitMessage ? ' Any payments' : ' Any payment'} greater than {showCardLimitMessage && showAchLimitMessage ? 'these amounts' : 'this amount'} will be rejected.
                                            </span>
                                        </div>
                                    </Grid>
                                }
                                {RecipientList()}
                                <Grid container item xs={12} justifyContent="flex-end">
                                    <Grid item>
                                        {showAddButton &&
                                            <Button variant="link"
                                                onClick={addRecipient}
                                                className={classes.subtitle}>
                                                + Add a Recipient
                                            </Button>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md="auto">
                            <Divider
                                height="25%"
                                variant="middle"
                                orientation='vertical'
                            ></Divider>
                        </Grid>
                        <Grid item md>
                            {PaymentMethod({ error })}
                            {showSurchargeSection &&
                                <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '20px' }}>
                                    <div style={{ display: 'inline-block' }}>
                                        <span style={{ display: 'inline-flex', fontWeight: 'bold' }}>Note:</span> Credit card payments are subject to a {portalSettings ? portalSettings.headnoteSurchargePercent : 0}% processing fee.  These fees do not apply to payments made with Debit cards.
                                    </div>
                                </Grid>
                            }
                            <Grid container item space={2}>
                                <Grid item xs={12}>
                                    <div className="form-group">
                                        <input type="checkbox" id="persist" name="persist" defaultChecked={formik.values.savePaymentMethod}
                                            onChange={e => {
                                                formik.setFieldValue('savePaymentMethod', e.target.checked);
                                            }}
                                        /> Save payment method for my personal future use.
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="form-group">
                                        <input type="checkbox" id="persistFirm" name="persistFirm" defaultChecked={formik.values.saveForFirmUse}
                                            onChange={e => {
                                                formik.setFieldValue('saveForFirmUse', e.target.checked);
                                            }}
                                        /> Save payment method for {firmInvoiceInfo?.firmName} firm to use in the future per their payment and billing terms.
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={2}>
                                <Grid item xs={12}>
                                    <div className={classes.subtitle}>Notes</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        label={"Payment Notes"}
                                        name={"payment_notes"}
                                        type={'text'}
                                        variant="outlined"
                                        value={formik.values.payment_notes}
                                        onChange={formik.handleChange}
                                    ></TextField>
                                </Grid>
                                {error.msg && <Alert variant='danger'>{error.msg}</Alert>}
                                <Grid container item xs={12} spacing={2} justifyContent="flex-end">
                                    <Grid item className={classes.termsAndConditions}>
                                        <div>By clicking “Submit Payment” you authorize a charge to the payment method</div>
                                        <div>
                                            provided for the designated amount and agree to our <TOS />
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <ThemeProvider theme={greenTheme}>
                                            {formik.isSubmitting && <Spinner animation="border" className="mr-2" role="status" />}
                                            <Button
                                                disabled={formik.isSubmitting}
                                                variant="contained"
                                                color="primary"
                                                type='submit'>
                                                SUBMIT PAYMENT
                                            </Button>
                                        </ThemeProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            </ThemeProvider>
        </StyledContainer>
    );

};

export default Link2PayV;